/* global gtag */
(function (window, document, body) {
  const onGtag = (e, current) => {
    const _anchor = e.closest('a');
    const _label = [current, ...current.querySelectorAll('[data-ga-label]')].find((f) =>
      f.isEqualNode(e.closest('[data-ga-label]'))
    );
    const _trigger = e.closest('[data-ga-trigger]');
    const _sending = e.closest('[data-ga-sending]');

    let gaAction = 'click';
    let gaCategory = e.dataset.gaCategory || 'engagement';
    let gaLabel = current.dataset.ga;

    if (_label) gaLabel = `${gaLabel}_${_label.dataset.gaLabel}`;
    if (_trigger) gaLabel = `${gaLabel}_${_trigger.dataset.gaTrigger}`;

    if (_anchor && _anchor.href !== '' && _anchor.hash.indexOf('#') === -1) {
      gaAction = 'link_click';
      if (_anchor.host !== window.location.host) {
        gaCategory = 'outbound';
        if (!_anchor.dataset.gaTrigger) gaLabel = `${gaLabel}_${_anchor.href}`;
      }
    }

    if (_sending) {
      gtag('event', gaAction, {
        event_category: gaCategory,
        event_label: _sending.dataset.gaSending
      });
    }
    return gtag('event', gaAction, {
      event_category: gaCategory,
      event_label: gaLabel
    });
  };

  body.addEventListener('click', (e) => {
    const target = e.target;
    const current = target.closest('[data-ga]');
    if (!current || typeof gtag !== 'function') return;
    return onGtag(target, current);
  });
})(window, document, document.body);
